body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  
  display: flex;
  justify-content: center;
  align-items: center;
}

#root{
  width: 100%;
}

/* body *{
  width: 100%;
  border: 1px solid black;
}

main{
  width: 100%;
}
control{
  width: 100%;
  height: 50%;
} */




